import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CommunityPage = () => (
    <Layout>
        <Seo title="Community" />

        <h2 className="text-primary text-l md:text-xl md:mt-5 md:mx-5">
            Communities and free sharing of knowledge have always helped me a lot on my way so far. Through my commitment, I would like to give something back.
        </h2>
        <p className="text-primary my-3 md:my-5 md:mx-5">
            If you have any questions, want to know more about the initiatives or just want to say hi,
            send me an <a className="hover:text-primary-link bg-gray-600 rounded-md" href="mailto:hello@normade.dev?Subject=Hello" target="_top" title="Email to Norma Driske">email</a>.
            I am happy to hear from you!
        </p>
        <h3 className="text-primary text-center text-m md:text-l md:mt-5 md:mx-5">Regular</h3>
        <div className="flex flex-wrap justify-center md:px-10">
            
            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://nullaufeins.org/" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/na1ev.png"
                        alt="nullaufeins e.V. Leipzig"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Founding member nullaufeins e.V. Leipzig
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://opentechschool.org/leipzig" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/ots.jpeg"
                        alt="Initiative OpenTechSchool Leipzig"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Organizer & mentor at OTS Leipzig, a weekly meetup for technology enthusiasts and creatives.
                </div>
            </div>

        </div>

        <h3 className="text-center text-primary text-m md:text-l md:mt-5 md:mx-5">Periodic & Previous</h3>
        
        <div className="flex flex-wrap justify-center md:px-10">

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://djangogirls.org" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/djangogirls.jpeg"
                        alt="Iniative Django Girls"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Organizer & mentor at Django Girls Leipzig, Dresden, Heidelberg, Basel, Belgrade
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://www.djangoproject.com/foundation/" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/django.png"
                        alt="Logo Django Software Foundation"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Individual Member, Django Software Foundation
                </div>
            </div>
        
           

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://jugendhackt.org" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/jugendhackt.jpeg"
                        alt="Initiative Jugend Hackt"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Mentor at jugendhackt.org
                </div>
            </div>
            
            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <StaticImage
                    src="../images/hackolaus_hackathon.jpg"
                    alt="Iniative Hack for Good Leipzig"
                    className="rounded-md transform hover:rotate-2"
                />
                <div className="pt-2 text-primary">
                    Initiator & organizer Hack for Good Leipzig <br />
                    A hackathon to support NGOs of the region.
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <StaticImage
                    src="../images/hackerinnen.jpeg"
                    alt="Initiative hackerinnen.space"
                    className="rounded-md transform hover:rotate-2"
                />
                <div className="pt-2 text-primary">
                    Co-initiator of hackerinnen.space, a project advocating for gender diversity in tech.
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://django-verein.de" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/ddv.png"
                        alt="Logo Deutscher Django Verein"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Member of board, German Django Association
                </div>
            </div>

        </div>

    </Layout >
)

export default CommunityPage